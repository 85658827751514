import React from 'react';
import classNames from 'classnames';

type BodyPropsType = {
  className?: string;
  itemClassName?: string;
  itemContainerClassName?: string;
  items: string[];
};

const List = ({
  className,
  itemClassName,
  itemContainerClassName,
  items,
}: BodyPropsType) => {
  return (
    <ul className={`list-outside mx-3 list-disc ${className}`}>
      {items.map(item => (
        <li
          key={item}
          className={classNames(itemContainerClassName, 'text-secondary')}
        >
          <span className={itemClassName || 'text-black'}>{item}</span>
        </li>
      ))}
    </ul>
  );
};

export default List;
