import React from 'react';
import Image from '../Image';
import { OurSolutionData } from '../../types/content/caseStudies';

type BodyPropsType = {
  solutionData: OurSolutionData;
};

const OurSolution = ({ solutionData }: BodyPropsType) => {
  return (
    <div className="md:flex md:flex-col">
      <h1
        data-testid="our-solution-header"
        className="font-bold text-2xl  
            md:font-extrabold md:leading-12 md:text-32px mb-2.5"
      >
        {solutionData.title}
      </h1>
      <p
        data-testid="our-solution-description"
        className="font-normal text-base md:w-9/12 lg:text-lg"
      >
        {solutionData.titleDescription}
      </p>
      <h2 className="font-semibold mt-5 md:mt-6 md:font-bold text-lg md:text-2xl leading-9 mb-2.5">
        {solutionData.headers.title}
      </h2>
      <p className="font-normal text-base mb-7 md:mb-10 md:w-9/12 lg:text-lg space-y-5 md:space-y-6">
        {solutionData.headers.descriptions.map(item => (
          <p>{item}</p>
        ))}
      </p>
      <div
        data-testid="our-solution-image"
        className="w-full aspect-w-1 aspect-h-1 md:aspect-w-16 md:aspect-h-9 overflow-hidden"
      >
        <Image
          src={`../../casestudy/${solutionData.image.file}`}
          webpSrc={`../../casestudy/${solutionData.image.webpFile}`}
          alt={`${solutionData.image.alt}`}
          className="w-full h-full object-cover rounded lg:w-full"
        />
      </div>
    </div>
  );
};

export default OurSolution;
