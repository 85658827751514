import React from 'react';
import Image from '../Image';
import Tag from '../Tag';
import { DetailsData, HeroData } from '../../types/content/caseStudies';

type BodyPropsType = {
  detailsData: DetailsData;
  heroData: HeroData;
};

const Hero = ({ detailsData, heroData }: BodyPropsType) => {
  return (
    <div
      data-testid="logo-background-container"
      className="lg:flex lg:flex-grow lg:flex-row"
    >
      <div className="inline-block relative md:w-10/12 lg:flex lg:w-8/12 lg:mb-5 grow">
        <Image
          src={`../../casestudy/${heroData.imagePath}`}
          webpSrc={`../../casestudy/${heroData.imageWebpPath}`}
          alt={`${heroData.imageAlt}`}
          className="h-full w-full object-cover rounded lg:mt-5"
        />
        <Image
          className="absolute w-full h-full top-1/2 left-1/2 transform -translate-x-2/4 -translate-y-2/4 lg:mt-5"
          src={`../../casestudy/${heroData.logoPath}`}
          alt={`${heroData.logoAlt}`}
        />
      </div>
      <div
        data-testid="details-container"
        className="hidden grow-0 lg:flex lg:flex-col lg:ml-9 lg:place-content-center lg:w-6/12 lg:translate-y-5 transform"
      >
        <div className="lg:w-9/12">
          <h1
            data-testid="details-header"
            className="font-bold text-2xl my-5  
            md:font-extrabold md:text-4xl md:my-0"
          >
            {detailsData.title}
          </h1>
        </div>
        <div className="lg:w-10/12">
          <div
            data-testid="tags-container"
            className="flex flex-wrap gap-y-2.5 gap-x-2.5 my-5"
          >
            {detailsData.tags.map((tag: string) => (
              <Tag content={tag} />
            ))}
          </div>
        </div>
        <div data-testid="details-description" className="lg:w-10/12">
          <p className="font-normal text-base hidden lg:text-lg lg:flex">
            {detailsData.description}
          </p>
        </div>
      </div>
      <div
        data-testid="details-mobile-tablet-container"
        className="lg:hidden mx-3 md:mx-64px lg:mx-68px md:mt-10 mt-5"
      >
        <div className="md:grid md:grid-rows-1 md:grid-cols-2 md:space-x-7 lg:hidden">
          <div className="w-full lg:hidden">
            <h1
              data-testid="details-header"
              className="font-bold text-2xl  
                md:font-extrabold md:text-4xl my-5 md:my-0"
            >
              {detailsData.title}
            </h1>
            <div
              data-testid="tags-container"
              className="flex flex-wrap gap-y-2.5 gap-x-2.5 my-5 md:my-0 md:mt-5"
            >
              {detailsData.tags.map((tag: string) => (
                <Tag content={tag} />
              ))}
            </div>
          </div>
          <p
            data-testid="details-description"
            className="font-normal text-base md:mb-0 lg:text-lg lg:hidden"
          >
            {detailsData.description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Hero;
