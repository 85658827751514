import React from 'react';
import Image from '../Image';
import { OutcomeData } from '../../types/content/caseStudies';

type BodyPropsType = {
  outcomeData: OutcomeData;
  titleClassName?: string;
};

const Outcome = ({ outcomeData, titleClassName }: BodyPropsType) => {
  const titleStyling = !titleClassName
    ? 'font-semibold md:font-bold text-lg md:text-2xl leading-9 mb-2.5 md:mb-3'
    : titleClassName;
  return (
    <div className="md:flex md:flex-col column-reverse">
      <h1 className={titleStyling}>{outcomeData.title}</h1>
      <div className="space-y-5 md:space-y-6">
        {outcomeData.descriptions.map((description: string) => (
          <p className="font-normal text-base md:w-9/12 lg:text-lg">
            {description}
          </p>
        ))}
      </div>
      <div className=" flex-col-reverse md:grid md:grid-rows-1 md:grid-cols-2 md:gap-x-5 lg:gap-x-7 mt-7 md:mt-10 flex">
        <p className="font-semibold lg:font-bold lg:text-2xl lg:leading-10 text-lg leading-9 mt-7 md:mt-0 md:place-self-center">
          {outcomeData.imageData.description}
        </p>
        <div className="w-full">
          <div className="w-full aspect-w-16 aspect-h-14 overflow-hidden">
            <Image
              className="w-full h-full object-cover rounded"
              src={`../../casestudy/${outcomeData.imageData.image.file}`}
              webpSrc={`../../casestudy/${outcomeData.imageData.image.webpFile}`}
              alt={`${outcomeData.imageData.image.alt}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Outcome;
