import React from 'react';
import Button from '../Button';
import { CtasData } from '../../types/content/caseStudies';

type BodyPropsType = {
  ctas: CtasData;
};

const Buttons = ({ ctas }: BodyPropsType) => {
  return (
    <div className="flex items-center flex-col md:flex-row md:gap-x-4 lg:gap-y-7 md:justify-center gap-y-4  mb-10 md:mb-12">
      <Button
        className="uppercase w-190px"
        type="inverse"
        text={ctas.backToPortfolio.desktop}
        to="/portfolio"
      />
      <Button
        className="uppercase w-190px"
        text={ctas.home.desktop}
        to="/home"
      />
    </div>
  );
};

export default Buttons;
