import React from 'react';
import Image from '../Image';
import { ProjectData } from '../../types/content/caseStudies';

type BodyPropsType = {
  projectData: ProjectData;
};

const Project = ({ projectData }: BodyPropsType) => {
  return (
    <div className="md:flex md:flex-col">
      {projectData.title !== null && (
        <h1
          data-testid="project-title"
          className="font-bold text-2xl  
            md:font-extrabold md:leading-12 md:text-32px mb-2.5 md:mb-3"
        >
          {projectData.title}
        </h1>
      )}
      <p
        data-testid="project-description"
        className="font-normal text-base mb-7 md:mb-10 md:w-9/12 lg:text-lg"
      >
        {projectData.description}
      </p>
      <div
        data-testid="project-image-description"
        className="w-full md:grid md:grid-rows-1 md:grid-cols-2 md:gap-x-5 lg:gap-x-7 mb-7 md:mb-10"
      >
        <p className="order-2 font-semibold text-lg lg:font-bold lg:text-2xl lg:leading-10 leading-9 mb-7 md:mb-0 md:place-self-center">
          {projectData.imageData.description}
        </p>
        <div data-testid="project-image" className="w-full order-1">
          <div className="w-full aspect-w-16 aspect-h-14 overflow-hidden">
            <Image
              className="w-full object-cover object-center rounded"
              src={`../../casestudy/${projectData.imageData.image.file}`}
              webpSrc={`../../casestudy/${projectData.imageData.image.webpFile}`}
              alt={`${projectData.imageData.image.alt}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Project;
