import * as React from 'react';
import PageSection from '../PageSection';
import Hero from './Hero';
import Specifications from './Specifications';
import Project from './Project';
import OurSolution from './OurSolution';
import Outcome from './Outcome';
import Buttons from './Buttons';
import DocumentHead from '../DocumentHead';
import { CaseStudiesData } from '../../types/content/caseStudies';

type BodyPropsType = {
  caseStudyData: CaseStudiesData;
};

const CaseStudyPage = ({ caseStudyData }: BodyPropsType) => {
  return (
    <div data-testid="case-study-container" className="md-900px:pt-9 pt-7">
      <DocumentHead content={caseStudyData.metadata} />
      <PageSection
        testId="hero"
        className="md:mb-10 max:mx-68px"
        justifyClass="md:flex md:justify-start max:justify-center"
      >
        <Hero
          detailsData={caseStudyData.details}
          heroData={caseStudyData.hero}
        />
      </PageSection>
      <PageSection
        testId="specifications-container"
        className="mx-3 md:mx-64px lg:mx-68px"
        justifyClass="flex justify-between max:justify-center"
      >
        <Specifications specificationsData={caseStudyData.specifications} />
      </PageSection>
      <PageSection
        testId="project-container"
        className="mx-3 lg:mx-68px md:mx-64px"
      >
        <Project projectData={caseStudyData.project} />
      </PageSection>
      <PageSection
        testId="our-solution-container"
        className="mb-7 md:mb-10 mx-3 lg:mx-68px md:mx-64px"
      >
        <OurSolution solutionData={caseStudyData.solution} />
      </PageSection>
      <PageSection
        testId="outcome-container"
        className="mb-10 md:mb-12 mx-3 lg:mx-68px md:mx-64px"
      >
        <Outcome outcomeData={caseStudyData.outcome} />
      </PageSection>
      <PageSection
        testId="buttons-container"
        className="mx-3 lg:mx-68px md:mx-64px"
      >
        <Buttons ctas={caseStudyData.ctas} />
      </PageSection>
    </div>
  );
};

export default CaseStudyPage;
