import classNames from 'classnames';
import React from 'react';

type BodyPropsType = {
  testId: string;
  children: JSX.Element | JSX.Element[];
  className?: string;
  hasDefaultBackground?: boolean;
  backgroundClass?: string;
  justifyClass?: string;
};

const PageSection = ({
  testId,
  children,
  className,
  hasDefaultBackground = false,
  backgroundClass,
  justifyClass,
}: BodyPropsType) => {
  const defaultBackground = classNames({
    'bg-background': hasDefaultBackground && !backgroundClass,
  });

  return (
    <div
      data-testid={testId}
      className={`${
        justifyClass || 'flex justify-center'
      } ${defaultBackground} ${backgroundClass || ''} ${className || ''}`}
    >
      <div className="max-w-max w-full">{children}</div>
    </div>
  );
};

export default PageSection;
