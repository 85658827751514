import React from 'react';

type BodyPropsType = {
  content: string;
};

const Tag = ({ content }: BodyPropsType) => {
  return (
    <React.Fragment>
      <div
        className="border box-border rounded-6xl border-secondary "
        data-testid="tag-container"
      >
        <div
          data-testid="tag-text-container"
          className="mx-3 font-normal text-sm tracking-tighter leading-"
        >
          {content}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Tag;
