import React from 'react';
import Image from '../Image';
import List from '../List';

import { SpecificationsData } from '../../types/content/caseStudies';

type BodyPropsType = {
  specificationsData: SpecificationsData;
};

const Specifications = ({ specificationsData }: BodyPropsType) => {
  return (
    <div className="mb-7 md:mb-10">
      <div className="md:grid md:grid-rows-1 md:grid-cols-2 md:space-x-7 lg:flex lg:flex-row lg:justify-evenly mt-7 md:mt-0">
        <div
          data-testd="specifications-feature-container"
          className="flex flex-col"
        >
          <h2 className="font-semibold text-base lg:font-bold lg:text-2xl mb-2 md:mb-4">
            {specificationsData.featureTitle}
          </h2>
          <List className="lg:text-lg" items={specificationsData.features} />
        </div>
        <div
          data-testd="specifications-technology-title"
          className="md:w-max flex flex-col"
        >
          <h2 className="font-semibold text-base lg:font-bold lg:text-2xl mt-5 md:mt-0 mb-2 md:mb-4">
            {specificationsData.technologyTitle}
          </h2>
          <List
            className="lg:text-lg"
            items={specificationsData.technologies}
          />
        </div>
      </div>
      <div
        data-testd="specifications-image"
        className="flex place-content-center my-10 md:h-400px lg:h-500px xl:h-600px"
      >
        <Image
          className={`w-full h-full object-center object-contain ${
            specificationsData.isRoundCorner ? 'rounded' : ''
          }`}
          src={`../../casestudy/${specificationsData.image.file}`}
          webpSrc={`../../casestudy/${specificationsData.image.webpFile}`}
          alt={`${specificationsData.image.alt}`}
        />
      </div>
    </div>
  );
};

export default Specifications;
